<template>
  <v-container class="container_hello">
    <v-row class="respon-flex" style="padding: 24px">
      <v-col cols="12">
        <h1 color="secondary">{{ weldingName }}</h1>
      </v-col>
      <v-row v-if="checkData() == false">
        <v-col
          class="text-center"
          lg="6"
          cols="12"
          v-for="item in data"
          :key="item.name"
        >
          <a :href="`/product/welding/${item.url}`">
            <v-img :src="require('../../assets/' + item.img)" contain />
          </a>

          <br />
          <h1 style="color: black !important">{{ item.name }}</h1>
        </v-col>
      </v-row>

      <v-row d-flex v-if="checkData() == true">
        <v-col class="responsive-fix text-center" cols="12" lg="8">
          <v-carousel
            eager
            v-if="this.productData !== null"
            cycle
            height="500"
            interval="6000"
            hide-delimiters
            show-arrows-on-hover
            rel="preload"
          >
            <v-carousel-item
              v-for="(item, i) in this.productData.img"
              :key="i"
              :src="require('../../assets/' + item)"
            ></v-carousel-item>
          </v-carousel>
          <br />
          <p style="text-align: justify;">{{ this.productData.desc }}</p>

          <br />
        </v-col>
        <v-col lg="4" cols="12" style="padding: 0px !important">
          <v-simple-table v-if="this.productData !== null && this.productData.name == 'Proton TG-150'">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>SPECIFICATION</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>INPUT VOLTAGE</td>
                  <td>{{ productData.inputVol}}</td>
                </tr>
                <tr>
                  <td>FREQUENCY</td>
                  <td>{{ productData.frequency }}</td>
                </tr>
                <tr>
                  <td>PHASE</td>
                  <td>{{ productData.phase }}</td>
                </tr>
                <tr>
                  <td>MAX INPUT CURRENT</td>
                  <td>{{ productData.maxinput }}</td>
                </tr>
                <tr>
                  <td>MAX POWER CONSUMPTION</td>
                  <td>{{ productData.maxpower }}</td>
                </tr>
                <tr>
                  <td>OPEN CIRCUIT VOLTAGE</td>
                  <td>{{ productData.opencircuit }}</td>
                </tr>
                <tr>
                  <td>WELDING CURRENT RANGE</td>
                  <td>{{ productData.weldingcurrent }}</td>
                </tr>
                <tr>
                  <td>DUTY CYCLE 60%</td>
                  <td>{{ productData.dutycycle }}</td>
                </tr>
                <tr>
                  <td>METHOD OF MACHINE COOLING</td>
                  <td>{{ productData.colling }}</td>
                </tr>
                <tr>
                  <td>USABLE ELECTRODE</td>
                  <td>{{ productData.usableelect }}</td>
                </tr>
                <tr>
                  <td>WEIGHT</td>
                  <td>{{ productData.weight }}</td>
                </tr>
                <tr>
                  <td>DIMENSION</td>
                  <td>{{ productData.dimensions }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table v-if="this.productData !== null && this.productData.name != 'Proton TG-150'">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>SPECIFICATION</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AC INPUT</td>
                  <td>{{ productData.acinput}}</td>
                </tr>
                <tr>
                  <td>CIRCUIT BREAKER</td>
                  <td>{{ productData.circuitbreak }}</td>
                </tr>
                <tr>
                  <td>MAX POWER CONSUMPTION</td>
                  <td>{{ productData.maxpower }}</td>
                </tr>
                <tr>
                  <td>EFFCIENCY</td>
                  <td>{{ productData.efficiency }}</td>
                </tr>
                <tr>
                  <td>OPEN CIRCUIT VOLTAGE</td>
                  <td>{{ productData.opencircuit }}</td>
                </tr>
                <tr>
                  <td>WELDING CURRENT RANGE</td>
                  <td>{{ productData.welding }}</td>
                </tr>
                <tr>
                  <td>DUTY CYCLE 40%</td>
                  <td>{{ productData.dutycycle40 }}</td>
                </tr>
                <tr>
                  <td>DUTY CYCLE 60%</td>
                  <td>{{ productData.dutycycle60 }}</td>
                </tr>
                <tr>
                  <td>DUTY CYCLE 100%</td>
                  <td>{{ productData.dutycycle100 }}</td>
                </tr>
                <tr>
                  <td>INSULATION CLASS</td>
                  <td>{{ productData.insulation }}</td>
                </tr>
                <tr>
                  <td>METHOD OF MACHINE COOLING</td>
                  <td>{{ productData.cooling }}</td>
                </tr>
                <tr>
                  <td>AMBIENT</td>
                  <td>{{ productData.ambient }}</td>
                </tr>
                <tr>
                  <td>PEOTECTION</td>
                  <td>{{ productData.protection }}</td>
                </tr>
                <tr>
                  <td>WEIGHT</td>
                  <td>{{ productData.weight }}</td>
                </tr>
                <tr>
                  <td>DIMENSION</td>
                  <td>{{ productData.dimensions }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.container_hello {
  max-width: 1200px !important;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}
.responsive-fix {
  .v-responsive__content {
    width: auto !important;
  }
  // .v-image__image--cover {
  //   background-size: contain !important;
  // }
}

.respon-flex {
  @media screen and (max-width: 1263px) {
     display: inline !important;
     }
     display: flex;
  }

.v-responsive {
  flex: none !important;
}
</style>
<script>
import json from "./Welding-data.json";
import router from "@/router";
export default {
  name: "Welding",
  created() {
    this.setData();
  },
  data: () => ({
    productData: null,
    weldingName: "Welding Machine",
    data: json,
    nowData: "",
    haveData: false,
  }),
  methods: {
    setData() {
      document.title = "Welding Machines - ORBIT., LTD. PART";
      json.forEach((data) => {
        if (this.$route.params.id == null) return;
        if (this.$route.params.id == data.url) {
          this.haveData = true;
          this.weldingName = data.name;
          document.title =
            this.weldingName + " - Welding Machines - ORBIT., LTD. PART";

          const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          };
          console.log(this.$route.params.id);
          fetch(
            `http://192.168.1.195:8080/api/getWelding?id=${this.$route.params.id}`,
            requestOptions
          ).then((r) => {
            if (r.status !== 200) {
              router.push({ name: "NotFound" });
              // this.$router.replace('/product/welding')
              return;
            }
            r.json().then((json) => {
              this.productData = json;
              console.log(json);
            });
          });
          this.nowData = data;
          return;
        }
      });
    },

    getImg() {
      console.log(this.nowData.img);
      return this.nowData.img;
    },
    checkData() {
      return this.haveData;
    },
    selectProduct(url) {
      this.$router.replace("/product/welding/" + url);
    },
  },
};
</script>
